exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact_us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-custom-demo-tsx": () => import("./../../../src/pages/custom/demo.tsx" /* webpackChunkName: "component---src-pages-custom-demo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-use-cases-fashion-tsx": () => import("./../../../src/pages/use_cases/fashion.tsx" /* webpackChunkName: "component---src-pages-use-cases-fashion-tsx" */),
  "component---src-pages-use-cases-furniture-tsx": () => import("./../../../src/pages/use_cases/furniture.tsx" /* webpackChunkName: "component---src-pages-use-cases-furniture-tsx" */),
  "component---src-pages-use-cases-index-tsx": () => import("./../../../src/pages/use_cases/index.tsx" /* webpackChunkName: "component---src-pages-use-cases-index-tsx" */)
}

